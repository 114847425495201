<template>
  <div class="bakkk">
    <div class="nav">
      <el-menu router :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item v-for="(item,index) in tabsList" :index="item.url" :key="index">
          {{item.name}}</el-menu-item>
      </el-menu>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {
  getLiking,
  musicAIMake,
} from "@/api/Mine";
export default {
  name: "MusicAI",
  data() {
    return {
      makeInstrumental:false,
      idea:'',
      tabsList:[
        {
          name: this.$t('musicAI'),
          url: "/musicai/musicaimake",
        },
        {
          name: this.$t('mymusicAI'),
          url: "/musicai/musicfy",
        },
      ]
    }
  },
  methods: {
    handleSelect() {

    },
  },
}
</script>

<style scoped  lang='less'>
.bakkk {
  display: flex;
  flex-direction: column;
}
.nav {
  width: 100%;
  height: 55px;
  background: @recommendMusicianListBGColor;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ::v-deep .el-menu {
    background: rgba(255, 255, 255, 0);

    &.el-menu--horizontal {
      border-bottom: none;
    }
  }

  ::v-deep .el-menu-item {
    border-bottom: none;
    text-decoration: none;
    list-style: none;
    font-size: 16px;
    font-weight: 400 !important;
    color: #999999 !important;

    &.is-active {
      background: rgba(255, 255, 255, 0);
      color: @headerSelectTextColor  !important;
      font-size: 16px;
      font-weight: 600 !important;
    }

    &:hover {
      background: rgba(255, 255, 255, 0);
      color: @headerSelectTextColor  !important;
      font-size: 16px;
      font-weight: 600 !important;
    }
  }
}

</style>